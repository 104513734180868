import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, PPV_ACTUALS_BUDGET_EXCHANGE_RATES } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Budget Exchange Rates", "has-active": false, "has-filter-inactive": false, "create-button-text": "Add New" }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "customButton", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onCopyClick } }, [_vm._v("Copy")])];
  }, proxy: true } : null, { key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v(" Show Budget Exchange Rates for Country ")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "default-active-first-option": "", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDel(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("text-field", { key: "CountryCode", attrs: { "data-index": "countryCode", "title": "Country", "sorter": true } }), _c("text-field", { key: "Currency", attrs: { "data-index": "currency", "title": "Currency", "sorter": true } }), _c("text-field", { key: "FinYear", attrs: { "data-index": "finYear", "title": "Fin Year", "sorter": true } }), _c("text-field", { key: "ExchangeRate", attrs: { "data-index": "exchangeRate", "title": "Exchange Rate", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var BudgetExchangeRates_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "BudgetExchangeRates",
  mixins: [MyUtil],
  inject: ["crud", "createRoute", "can"],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  created() {
    this.getCountries(false);
    this.crud.setQueryString("CountryID", this.countryIdAU);
  },
  methods: {
    onCopyClick() {
      this.$router.push("/ppv-actuals/budget-exchange-rates/copy");
    },
    onDel(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Budget Exchange Rate?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Budget Exchange Rate?`,
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.delete(`${this.apiUrl}/ppv/budget-exchange-rates`, {
          data: {
            countryId: record.countryId,
            currency: record.currency,
            finYear: record.finYear
          }
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch((err) => console.error(err));
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "58bb0295", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var BudgetExchangeRates = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "ppv.budget-exchange-rates", "create-route": "/ppv-actuals/budget-exchange-rates/create", "edit-route": "/ppv-actuals/budget-exchange-rates/:id", "page": _vm.page } }, [_c("budget-exchange-rates")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    BudgetExchangeRates
  },
  data() {
    return {
      page: PPV_ACTUALS_BUDGET_EXCHANGE_RATES,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-actuals",
          title: "PPV Actuals",
          path: ""
        },
        {
          key: "budget-exchange-rates",
          title: "Budget Exchange Rates",
          path: "/ppv-actuals/budget-exchange-rates"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
